import { BrMd } from './BrSml';
import { constants } from './constants';
import { EAlterState } from './CrudStppr';
import { Dimension, IDimension } from './Dim';

export interface IAccPkgSml {
  key: string;
  desc: string;
  name: string;
  active: boolean;
  visible: boolean;
}

export interface IAccPkgSmlPayload {
  accessPackages: IAccPkgSml[];
}

interface IAccPkgBase {
  key: string;
  name: string;
  desc: string;
  active: boolean;
  visible: boolean;
  certifiable: boolean;
  approvalLevel: number;
  approvalType: string;
  maxAllowedDays: number;
  certActionPeriod: number;
  defaultAllowedDays: number;
  roleOwner: string;
  roleActivityFlag: string;
  roleDependency: string;
  functionalArea: string;
  autoExtensionFlag: boolean;
  autoExtensionCondition?: string;
  maxAutoExtensionAllowed?: number | null | string;
  autoApprovalFlag: boolean;
  autoApprovalCondition?: string;
  maxAutoApprovalAllowed?: number | null | string;
  jobId: string;
}

export interface IAccPkgPayload extends IAccPkgBase {
  userType: string;
  startDttm: string;
  endDateDttm: string;
  primaryApprover: string;
  secondLevelApprover: string;
}

export interface IAccPkgFg extends IAccPkgBase {
  userTypes: string[];
  startDate: string;
  endDate: string;
  primaryWGApprover: string;
  secondaryWGApprover: string;
  mappedBrs: string[];
}

export interface IAccPkgValBase {
  AccessPackageKey?: string;
  AccessPackageName: string;
  AccessPackageDescription?: string;
  AccessPackageUserType: string;
  AccessPackageStartDttm?: string;
  AccessPackageEndDateDttm?: string;
  RoleOwner: string;
  RoleActivityFlag: string;
  FunctionalArea: string;
  IsActive: boolean;
  IsVisible: boolean;
  IsCertifiable: boolean;
  AutoExtensionFlag: boolean;
  AutoExtensionCondition?: string;
  MaxAutoExtensionAllowed?: number;
  AutoApprovalFlag: boolean;
  AutoApprovalCondition?: string;
  MaxAutoApprovalAllowed?: number;
  JobId: string;
}

export interface IAccPkgValApprovers {
  AccessPackageKey?: string;
  PrimaryApprover: string;
  SecondLevelApprover?: string;
  ApprovalLevel: number;
  ApprovalType: string;
  MaxAllowedDays: number;
  CertActionPeriod: number;
  DefaultAllowedDays: number;
}

export class AccPkg {
  key: string;
  name: string;
  desc: string;
  userTypes: string[] = [];
  startDate: Date;
  endDate: Date;
  roleOwner = constants.roleOwner;
  roleActivityFlag = constants.tbd;
  roleDependency = constants.tbd;
  functionalArea = constants.tbd;
  primaryWGApprover: string;
  secondaryWGApprover: string;
  approvalLevel: number;
  approvalType: string;
  maxAllowedDays = constants.maxAllowedDays;
  certActionPeriod: number;
  defaultAllowedDays = constants.defaultAllowedDays;
  active = true;
  visible = true;
  certifiable = true;
  autoExtensionFlag = false;
  autoExtensionCondition: string;
  maxAutoExtensionAllowed: number | null | string = 720;
  autoApprovalFlag = false;
  autoApprovalCondition: string;
  maxAutoApprovalAllowed: number | null | string = 720;
  jobId: string;
  constructor(params: IAccPkgPayload) {
    if (params) {
      this.key = params.key;
      this.name = params.name;
      this.desc = params.desc;
      if (typeof params.userType === 'string' && params.userType.length > 0) {
        this.userTypes = params.userType.split(',');
      }
      this.startDate = stringToDate(params.startDttm);
      this.endDate = stringToDate(params.endDateDttm);
      this.roleOwner = params.roleOwner || constants.roleOwner;
      this.roleActivityFlag = params.roleActivityFlag || constants.tbd;
      this.roleDependency = params.roleDependency || constants.tbd;
      this.functionalArea = params.functionalArea || constants.tbd;
      this.primaryWGApprover = params.primaryApprover;
      this.secondaryWGApprover = params.secondLevelApprover;
      this.approvalLevel = params.approvalLevel;
      this.approvalType = params.approvalType;
      this.maxAllowedDays = params.maxAllowedDays;
      this.certActionPeriod = params.certActionPeriod;
      this.defaultAllowedDays = params.defaultAllowedDays;
      this.autoExtensionFlag = params.autoExtensionFlag || false;
      this.autoExtensionCondition = params.autoExtensionCondition;
      this.maxAutoExtensionAllowed = params.maxAutoExtensionAllowed;
      if (this.autoExtensionCondition === null) {
        this.autoExtensionCondition = '';
      }
      if (this.maxAutoExtensionAllowed === null) {
        this.maxAutoExtensionAllowed = '';
      }
      if (params.approvalLevel !== 2) {
        this.secondaryWGApprover = '';
        this.approvalLevel = 1;
      }
      if (this.approvalType !== 'AP' && this.approvalType !== 'AP+DS') {
        this.approvalType = '';
      }
      this.active = params.active;
      this.visible = params.visible;
      this.certifiable = params.certifiable;
      this.autoApprovalFlag = params.autoApprovalFlag;
      this.autoApprovalCondition = params.autoApprovalCondition || '';
      this.maxAutoApprovalAllowed = params.maxAutoApprovalAllowed;
      if (this.maxAutoApprovalAllowed === null) {
        this.maxAutoApprovalAllowed = '';
      }
      this.jobId = params.jobId;
    }
  }

  get userType(): string {
    return this.userTypes.join();
  }

  get startDttm() {
    return this.startDate ? this.startDate.toJSON().substring(0, 19) : '';
  }
  set startDttm(str: string) {
    const d = new Date(str || '');
    if (d instanceof Date && isFinite(d.getTime())) {
      this.startDate = d;
    } else {
      this.startDate = undefined;
    }
  }
  get endDttm() {
    return this.endDate ? this.endDate.toJSON().substring(0, 19) : '';
  }
  set endDttm(str: string) {
    const d = new Date(str || '');
    if (d instanceof Date && isFinite(d.getTime())) {
      this.endDate = d;
    } else {
      this.endDate = undefined;
    }
  }

  get payload(): IAccPkgPayload {
    const obj: IAccPkgPayload = {
      key: this.key,
      name: this.name,
      desc: this.desc,
      userType: this.userType,
      startDttm: this.startDttm,
      endDateDttm: this.endDttm,
      roleOwner: this.roleOwner,
      roleActivityFlag: this.roleActivityFlag,
      roleDependency: this.roleDependency,
      functionalArea: this.functionalArea,
      primaryApprover: this.primaryWGApprover,
      secondLevelApprover: this.secondaryWGApprover,
      approvalLevel: this.approvalLevel,
      approvalType: this.approvalType,
      maxAllowedDays: this.maxAllowedDays,
      certActionPeriod: this.certActionPeriod,
      defaultAllowedDays: this.defaultAllowedDays,
      certifiable: this.certifiable,
      visible: this.visible,
      active: this.active,
      autoExtensionFlag: this.autoExtensionFlag,
      autoExtensionCondition: this.autoExtensionCondition,
      maxAutoExtensionAllowed: this.maxAutoExtensionAllowed,
      autoApprovalFlag: this.autoApprovalFlag,
      autoApprovalCondition: this.autoApprovalCondition,
      maxAutoApprovalAllowed: this.maxAutoApprovalAllowed,
      jobId: (() => {
        if (this.jobId === null) {
          return null;
        } else if (typeof this.jobId === 'string' && this.jobId.trim() === '') {
          return null;
        } else {
          return this.jobId.trim();
        }
      })(),
    };
    if (!this.autoExtensionCondition) {
      delete obj.autoExtensionCondition;
    }
    if (typeof this.maxAutoExtensionAllowed !== 'number') {
      delete obj.maxAutoExtensionAllowed;
    } else if (this.maxAutoExtensionAllowed >= 0) {
      obj.maxAutoExtensionAllowed = this.maxAutoExtensionAllowed;
    }
    if (!obj.autoApprovalCondition) delete obj.autoApprovalCondition;
    if (!obj.jobId || this.jobId === null) delete obj.jobId;
    if (typeof this.maxAutoApprovalAllowed !== 'number') {
      delete obj.maxAutoApprovalAllowed;
    } else if (this.maxAutoApprovalAllowed >= 0) {
      obj.maxAutoApprovalAllowed = this.maxAutoApprovalAllowed;
    }

    return obj;
  }

  /** Base validation payload */
  get payloadValBase() {
    const obj: IAccPkgValBase = {
      AccessPackageName: this.name,
      AccessPackageUserType: this.userType,
      RoleOwner: this.roleOwner,
      RoleActivityFlag: this.roleActivityFlag,
      FunctionalArea: this.functionalArea,
      IsActive: this.active,
      IsCertifiable: this.certifiable,
      IsVisible: this.visible,
      AutoExtensionFlag: this.autoExtensionFlag,
      AutoExtensionCondition: this.autoExtensionCondition,
      MaxAutoExtensionAllowed: (() => {
        if (this.maxAutoExtensionAllowed === null) {
          return null;
        } else if (typeof this.maxAutoExtensionAllowed === 'string') {
          return parseInt(this.maxAutoExtensionAllowed, 10) || null;
        } else {
          return this.maxAutoExtensionAllowed;
        }
      })(),
      AutoApprovalFlag: this.autoApprovalFlag,
      AutoApprovalCondition: this.autoApprovalCondition,
      MaxAutoApprovalAllowed: (() => {
        if (this.maxAutoApprovalAllowed === null) {
          return null;
        } else if (typeof this.maxAutoApprovalAllowed === 'string') {
          return parseInt(this.maxAutoApprovalAllowed, 10) || null;
        } else {
          return this.maxAutoApprovalAllowed;
        }
      })(),
      JobId: (() => {
        if (this.jobId === null) {
          return null;
        } else if (typeof this.jobId === 'string' && this.jobId.trim() === '') {
          return null;
        } else {
          return this.jobId;
        }
      })(),
    };
    if (this.key) {
      obj.AccessPackageKey = this.key;
    }
    if (this.desc) {
      obj.AccessPackageDescription = this.desc;
    }
    if (this.startDate) {
      obj.AccessPackageStartDttm = this.startDttm;
    }
    if (this.startDate) {
      obj.AccessPackageEndDateDttm = this.endDttm;
    }
    if (!this.autoExtensionCondition) {
      delete obj.AutoExtensionCondition;
    }
    if (typeof this.maxAutoExtensionAllowed !== 'number') {
      delete obj.MaxAutoExtensionAllowed;
    } else if (this.maxAutoExtensionAllowed >= 0) {
      obj.MaxAutoExtensionAllowed = this.maxAutoExtensionAllowed;
    }
    if (!this.autoApprovalCondition) {
      delete obj.AutoApprovalCondition;
    }
    if (!this.jobId || this.jobId === null) {
      delete obj.JobId;
    }
    if (typeof this.maxAutoApprovalAllowed !== 'number') {
      delete obj.MaxAutoApprovalAllowed;
    } else if (this.maxAutoApprovalAllowed >= 0) {
      obj.MaxAutoApprovalAllowed = this.maxAutoApprovalAllowed;
    }

    return obj;
  }
  /** Approvers validation payload */
  get payloadValApprovers() {
    const obj: IAccPkgValApprovers = {
      ApprovalLevel: this.approvalLevel,
      ApprovalType: this.approvalType,
      PrimaryApprover: this.primaryWGApprover,
      SecondLevelApprover: this.secondaryWGApprover,
      MaxAllowedDays: this.maxAllowedDays,
      CertActionPeriod: this.certActionPeriod,
      DefaultAllowedDays: this.defaultAllowedDays,
    };
    if (this.key) {
      obj.AccessPackageKey = this.key;
    }
    return obj;
  }
}

export function fgToAccPkg(fg: IAccPkgFg) {
  return new AccPkg({
    active: fg.active,
    approvalLevel: fg.approvalLevel,
    approvalType: fg.approvalType,
    certifiable: fg.certifiable,
    defaultAllowedDays: fg.defaultAllowedDays,
    desc: fg.desc,
    endDateDttm: fg.endDate,
    functionalArea: fg.functionalArea,
    key: fg.key,
    maxAllowedDays: fg.maxAllowedDays,
    certActionPeriod: fg.certActionPeriod,
    name: fg.name,
    primaryApprover: fg.primaryWGApprover,
    roleOwner: fg.roleOwner,
    roleActivityFlag: fg.roleActivityFlag,
    roleDependency: fg.roleDependency,
    secondLevelApprover: fg.secondaryWGApprover,
    startDttm: fg.startDate,
    userType: fg.userTypes.toString(),
    visible: fg.visible,
    autoExtensionFlag: fg.autoExtensionFlag || false,
    autoExtensionCondition: fg.autoExtensionCondition,
    maxAutoExtensionAllowed: fg.maxAutoExtensionAllowed,
    autoApprovalFlag: fg.autoApprovalFlag || false,
    autoApprovalCondition: fg.autoApprovalCondition,
    maxAutoApprovalAllowed: fg.maxAutoApprovalAllowed,
    jobId: fg.jobId,
  });
}

function stringToDate(str: string): Date {
  if (str) {
    const d = new Date(str);
    if (d.getTime() > 0) return d;
  }
  return undefined;
}

/**
 * The Crud stepper abstract class is used by the AccPkg crud stepper
 */
export abstract class AccPkgCrudStppr<T> {
  id = -1;
  isDisabled = false;
  action: EAlterState;
  updatedState: AccPkgCrudStppr<T>;

  constructor(i: number) {
    this.id = i;
    this.action = i < 0 ? EAlterState.new : EAlterState.nothing;
  }

  abstract get payload();
  abstract get subPayload();
  abstract reset(): void;
}

// ---------------------------------------------------- Mapped dim
export interface IAccPkgMappedBrDim {
  active: boolean;
  apDmKey: string;
  apDmValue: string;
  apDtKey: string;
  brDmKey: string;
  brDmValue: string;
  brDtKey: string;
  brKey: string;
  requiredSelection: boolean;
}

export interface IAccPkgMappedBrDimPayload {
  key: string;
  businessRoleDimensions: IAccPkgMappedBrDim[];
}

export interface IAccPkgValMbrDim {
  AccessPackageKey: string;
  AccessPackageDimensionKey: string;
  BusinessRoleKey: string;
  BusinessRoleDimensionKey: string;
  IsActive: boolean;
  requiredSelection: boolean;
}

export interface IAccPkgMBRdimPayload {
  apDm?: IDimension;
  brDm?: IDimension;
  br?: BrMd;
  apDmKey?: string;
  apDmValue?: string;
  apDtKey: string;
  brKey: string;
  brDmKey?: string;
  brDmValue?: string;
  brDtKey: string;
  active: boolean;
  requiredSelection: boolean;
}

export interface IAccPkgMappedBRdimPayload {
  apDmKey: string;
  apDtKey: string;
  brKey: string;
  brDmKey: string;
  brDtKey: string;
  isActive: boolean;
  requiredSelection: boolean;
}

export class AccPkgMappedBrDim extends AccPkgCrudStppr<AccPkgMappedBrDim> {
  apDm: IDimension;
  brDm: IDimension;
  br: BrMd;
  apDtKey = '';
  brDtKey = '';
  requiredSelection = false;
  active = true;

  constructor(params?: IAccPkgMBRdimPayload, i?: number) {
    super(i);
    if (params) {
      if ('apDm' in params && 'brDm' in params) {
        this.brDm = params.brDm;
        this.apDm = params.apDm;
        this.br = new BrMd(params.br);
      } else {
        this.apDm = new Dimension({
          key: params.apDmKey,
          value: params.apDmValue,
          active: true,
          riskScore: '0',
          visible: true,
        });
        this.brDm = new Dimension({
          key: params.brDmKey,
          value: params.brDmValue,
          active: true,
          riskScore: '0',
          visible: true,
        });
      }

      this.active = params.active || false;
      this.requiredSelection = params.requiredSelection || false;

      this.apDtKey = params.apDtKey;
      this.brDtKey = params.brDtKey;
      this.id = i;
      this.action = i < 0 ? EAlterState.new : EAlterState.nothing;
    }
  }

  get brKey(): string {
    return this.br ? this.br.key : '';
  }

  get brAppInfo(): string {
    return this.br?.appInfo || '';
  }

  get apDmInfo(): string {
    return this.apDm ? `${this.apDm.key}: ${this.apDm.value}` : '';
  }

  get brInfo(): string {
    return this.br ? `${this.br.display}` : '';
  }

  get brDmInfo(): string {
    return this.brDm ? `${this.brDm.key}: ${this.brDm.value}` : '';
  }

  get payload() {
    const t =
      this.action === EAlterState.update
        ? (this.updatedState as AccPkgMappedBrDim)
        : this;
    return {
      apDtKey: t.apDtKey,
      apDm: t.apDm,
      br: t.br?.payload,
      brDtKey: t.brDtKey,
      brDm: t.brDm,
      requiredSelection: t.requiredSelection,
      active: t.active,
    };
  }

  get subPayload(): IAccPkgMappedBRdimPayload {
    const t =
      this.action === EAlterState.update
        ? (this.updatedState as AccPkgMappedBrDim)
        : this;
    return {
      apDmKey: t.apDm?.key,
      apDtKey: t.apDtKey,
      brKey: t.br?.key,
      brDmKey: t.brDm?.key,
      brDtKey: t.brDtKey,
      isActive: t.isDisabled ? false : t.active,
      requiredSelection: t.requiredSelection || false,
    };
  }

  reset(): void {
    const us = this.updatedState as AccPkgMappedBrDim;
    if (us) {
      this.apDm = us.apDm;
      this.apDtKey = us.apDtKey;
      this.brDm = us.brDm;
      this.brDtKey = us.brDtKey;
      this.active = true;
      this.requiredSelection = true;
      this.isDisabled = false;
      this.br = us.br || this.br;
    }
    this.action = EAlterState.nothing;
  }
}

// ---------------------------------------------------- Mapped data sel
export interface IAccPkgMappedDataSel {
  apKey: string;
  dsKey: string;
  dsValue: string;
  primaryApproverWorkgroup: string;
  recommended: boolean;
  apDs?: AccPkgMappedDataSelAvail;
}

export interface IAccPkgMappedDataSelPayload {
  accessPackageDataSelections: IAccPkgMappedDataSel[];
}

export interface IAccPkgValMdataSel {
  DataSelectionKey: string;
  AccessPackageKey: string;
  PrimaryApproverWorkgroup: string;
  IsActive: boolean;
}

export interface IAccPkgMappedDSPayload {
  apDs?: AccPkgMappedDataSelAvail;
  dsKey?: string;
  dsValue?: string;
  primaryApproverWorkgroup: string;
  recommended: boolean;
}

export interface IAccPkgMDataSelPayload {
  dsKey: string;
  primaryApproverWorkGroup: string;
  active: boolean;
}

export class AccPkgMappedDataSel extends AccPkgCrudStppr<AccPkgMappedDataSel> {
  apDs: AccPkgMappedDataSelAvail;
  primaryApproverWorkgroup = '';
  recommended = true;

  constructor(params?: IAccPkgMappedDSPayload, i?: number) {
    super(i);
    if (params) {
      if (params.dsKey) {
        this.apDs = new AccPkgMappedDataSelAvail({
          key: params.dsKey,
          value: params.dsValue,
          active: true,
        });
      } else if (params.apDs) {
        this.apDs = params.apDs;
      }

      this.recommended = params.recommended;
      this.primaryApproverWorkgroup = params.primaryApproverWorkgroup;
    }
  }

  get apDsInfo(): string {
    return this.apDs ? `${this.apDs.displayName}` : '';
  }

  get workgroupInfo(): string {
    return this.primaryApproverWorkgroup || '';
  }

  get activeInfo() {
    return this.recommended ? 'Yes' : 'No';
  }

  get payload() {
    const t =
      this.action === EAlterState.update
        ? (this.updatedState as AccPkgMappedDataSel)
        : this;
    return {
      apDs: t.apDs,
      primaryApproverWorkgroup: t.primaryApproverWorkgroup,
      recommended: t.recommended,
    };
  }

  get subPayload(): IAccPkgMDataSelPayload {
    const t =
      this.action === EAlterState.update
        ? (this.updatedState as AccPkgMappedDataSel)
        : this;
    return {
      dsKey: t.apDs?.key,
      primaryApproverWorkGroup: t.primaryApproverWorkgroup,
      active: t.recommended,
    };
  }

  reset(): void {
    const us = this.updatedState as AccPkgMappedDataSel;
    if (us) {
      this.apDs = us.apDs;
      this.primaryApproverWorkgroup = us.primaryApproverWorkgroup;
      this.recommended = us.recommended;
    }
    this.action = EAlterState.nothing;
  }
}

export interface IAccPkgMappedDataSelAvail {
  key: string;
  value: string;
  active: boolean;
}

export interface IAccPkgMappedDataSelAvailPayload {
  dataSelections: IAccPkgMappedDataSelAvail[];
}

export class AccPkgMappedDataSelAvail {
  key = '';
  value = '';
  active = true;

  constructor(data?: IAccPkgMappedDataSelAvail) {
    if (data) {
      this.key = data.key;
      this.value = data.value;
      this.active = data.active;
    }
  }

  get displayName() {
    if (!this.key) {
      return `${this.value}`;
    }
    return `${this.key}: ${this.value}`;
  }
}
