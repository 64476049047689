import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { constants } from 'src/app/models/constants';

export const invalidDefaultAllowedDaysValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const maxDays = control.get('maxAllowedDays');
  const defaultDays = +control.get('defaultAllowedDays').value;

  const max = maxDays.valid ? +maxDays.value : constants.maxAllowedDays;
  const invalidDefaultAllowedDays = `Default allowed days must be between ${constants.minAllowedDays} and ${max}`;

  if (defaultDays < constants.minAllowedDays)
    return { invalidDefaultAllowedDays };
  return defaultDays > max ? { invalidDefaultAllowedDays } : null;
};

const reqFlds = [
  'name',
  'type',
  'selfCertPeriod',
  'description',
  'userTypes',
  'roleOwner',
  'roleDependency',
  'autoApprovalCondition',
  'privilegedAccess',
  'roleActivityFlag',
  'functionalArea',
  'certActionPeriod',
  'autoExtensionCondition',
  'maxAutoExtensionAllowed',
  'licensing',
  'maxAllowedDays',
  'defaultAllowedDays',
  'maxAutoApprovalAllowed',
  'profile',
];
const reqFldsLen = reqFlds.length;
export const invalidBRInfoValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  for (let i = 0; i < reqFldsLen; ++i) {
    if (control.get(reqFlds[i]).invalid) {
      return { invalidBRInfo: true };
    }
  }
  return null;
};

export const invalidApproverValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const primaryWGApprover = control.get('primaryWGApprover');

  return primaryWGApprover.invalid ? { invalidApprover: true } : null;
};

const qsnrFlds = [
  'dataPrivacy',
  'insiderAlert',
  'riskScoreMultiplier',
  'dataClassification',
];
const qsnrFldsLen = qsnrFlds.length;
export const invalidQuestionnaireValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  for (let i = 0; i < qsnrFldsLen; ++i) {
    if (control.get(qsnrFlds[i]).invalid) {
      return { invalidQuestionnaire: true };
    }
  }
  return null;
};
