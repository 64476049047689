<header>
    <nav>
      <div class="left wrapper">
        <app-hamburger (click)="hamToggleClick()"></app-hamburger>
        <a class="logo" [routerLink]='["/about"]'><img src="/assets/images/accenture-logo.PNG"></a>
        <div class="container" [class.show]="hamToggleOn">
          <div class="back-drop" (click)="hamToggleClick()">&nbsp;</div>
          <div class="nav-wrapper">
            <app-nav-menu *ngFor="let item of navItems" [item]="item" (notify)="dropdownClicked($event)"></app-nav-menu>
          </div>
        </div>
      </div>
      <div class="right wrapper">
        <app-auth-user></app-auth-user>
      </div>
    </nav>
  </header>
  