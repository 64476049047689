import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BrMd } from 'src/app/models/BrSml';
import { constants } from 'src/app/models/constants';
const MAX_CSV_FILE_SIZE_MB = 5242880; // 5 MB, converting the value to bytes
const CSV_FILE_EXT = 'csv';

export const invalidBaseFldsValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const flds = [
    'name',
    'desc',
    'userTypes',
    'startDate',
    'endDate',
    'roleOwner',
    'roleActivityFlag',
    'functionalArea',
    'autoExtensionCondition',
    'maxAutoExtensionAllowed',
    'autoApprovalCondition',
    'maxAutoApprovalAllowed',
    'jobId',
  ];
  for (let i = 0, len = flds.length; i < len; ++i) {
    const fld = flds[i];
    if (control.get(fld).invalid) {
      return { invalidBaseFlds: true };
    }
  }

  return null;
};

export const invalidApproverFldsValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const flds = [
    'approvalLevel',
    'approvalType',
    'primaryWGApprover',
    'secondaryWGApprover',
    'maxAllowedDays',
    'certActionPeriod',
    'defaultAllowedDays',
  ];
  for (let i = 0, len = flds.length; i < len; ++i) {
    const fld = flds[i];
    if (control.get(fld).invalid) {
      return { invalidApproverFlds: true };
    }
  }

  return null;
};

export const invalidMappedBrFldValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const brs: BrMd[] = control.get('mappedBrs').value;

  if (Array.isArray(brs)) {
    for (let i = 0, len = brs.length; i < len; ++i) {
      if (brs[i].selected) {
        return null;
      }
    }
  }

  return { invalidMappedBrFld: true };
};

export const invalidDefaultAllowedDaysValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const maxDays = control.get('maxAllowedDays');
  const defaultDays = +control.get('defaultAllowedDays').value;

  const max = maxDays.valid ? +maxDays.value : constants.maxAllowedDays;
  const invalidDefaultAllowedDays = `Default allowed days must be between ${constants.minAllowedDays} and ${max}`;

  if (defaultDays < constants.minAllowedDays)
    return { invalidDefaultAllowedDays };
  return defaultDays > max ? { invalidDefaultAllowedDays } : null;
};

export const invalidFilePropsValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (control && control.valid && control.value) {
    let fileExtMatch = control.value.name.match(/.+\.(csv)$/i);
    let fileExt: string;

    if (fileExtMatch && fileExtMatch.length >= 1) {
      fileExt = fileExtMatch[1].toLocaleLowerCase();
    } else {
      fileExtMatch = control.value.name.match(/\.(.+)$/i);
      fileExt = Array.isArray(fileExtMatch)
        ? fileExtMatch[1]
        : control.value.name;
      return {
        invalidFileProps: `The file extension "${fileExt}" is not in the list of accepted file extension (${CSV_FILE_EXT}). Please select another file and try again.`,
      };
    }

    if (control.value.size > MAX_CSV_FILE_SIZE_MB) {
      return {
        invalidFileProps: `The file size exceeds the maximum value of 5 Megabyte. Please try again.`,
      };
    }
  }

  return null;
};
