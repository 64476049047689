<div class="form-field {{frmFldClss}}" [ngClass]="{ disabled: abstrctCntrl.disabled }">
    <div class="label-wrapper lft-rht">
        <div class="lft">
        <label [for]="inptId" *ngIf="label">{{label}}<sup *ngIf="isRequired && !abstrctCntrl.disabled">&#9733;</sup></label>
        <app-info-tooltip *ngIf="tooltip" [hoverText]="tooltip">
        </app-info-tooltip>
    </div>
    <div class="rht">
        <ng-content select="[rht-header]"></ng-content>
    </div>
    </div>
    <div class="input-wrapper">
        <ng-content></ng-content>
    </div>
    <div class="form-err-field" *ngIf="abstrctCntrl.invalid && (abstrctCntrl.dirty || abstrctCntrl.touched)">
        <ng-container *ngIf="abstrctCntrl.errors?.apiErr">{{abstrctCntrl.errors.apiErr}}</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.required">{{label ? label : customLabelName}} is required.</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.pattern">{{errPttrn}}.</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.maxlength">{{(label ? label : customLabelName) + ' cannot exceed ' +
            abstrctCntrl.errors.maxlength?.requiredLength
            + ' (actual: ' +
            abstrctCntrl.errors.maxlength?.actualLength
            + ')'}}.</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.minlength">{{label + ' cannot exceed ' +
            abstrctCntrl.errors.minlength?.requiredLength
            + ' (actual: ' +
            abstrctCntrl.errors.minlength?.actualLength
            + ')'}}.</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.max">{{label + ' cannot exceed ' +
            abstrctCntrl.errors.max?.max
            + ' (actual: ' +
            abstrctCntrl.errors.max?.actual
            + ')'}}.</ng-container>
        <ng-container *ngIf="abstrctCntrl.errors?.min">{{label + ' is below the minimum value of ' +
            abstrctCntrl.errors.min?.min
            + ' (actual: ' +
            abstrctCntrl.errors.min?.actual
            + ')'}}.</ng-container>
        <ng-content select="[errors]"></ng-content>
    </div>
    <ng-content select="[notes]"></ng-content>
</div>
