import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavItem, getNavItems } from './nav-items';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  private currNavItem?: NavItem;
  private subs: Subscription[] = [];
  navItems: NavItem[] = [];
  hamToggleOn = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.navItems = getNavItems(this.authService); // Initialize navItems using AuthService
    this.subs.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.hamToggleOn = false;
        }
      }),
    );
  }

  dropdownClicked(navItem: NavItem): void {
    if (this.currNavItem && this.currNavItem.name !== navItem.name) {
      this.currNavItem.isExpanded = false;
    }
    this.currNavItem = navItem;
  }

  hamToggleClick(): void {
    this.hamToggleOn = !this.hamToggleOn;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
