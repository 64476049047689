import { Component, OnInit } from '@angular/core';
import { MsmService } from '../../msm/msm.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
})
export class AppBannerComponent implements OnInit {
  constructor(
    private msmFgService: MsmService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/release') {
          this.msmFgService.resetBannerFrReleasePage();
        }
      }
    });
  }

  get appDisplayName(): string {
    return this.msmFgService.appDisplayName;
  }

  get actionName(): string {
    return this.msmFgService.msmActionName;
  }

  get brName(): string {
    return this.msmFgService.brName;
  }

  get releaseName(): string {
    return `Release Schedule`;
  }
}
