export interface ISignedUrlBody {
  signedUrl: string;
  expiresIn: string;
  tag: string;
}

interface IEmailObj {
  email: string;
}

export interface ISignedEmails {
  emails: IEmailObj[];
}

export const awsHeader = 'x-amz-tagging';
