import { Application, SimpleApplication } from './Application';
import { IbrValPayload } from './IbrValPayload';
import { constants } from './constants';
import { DataClassification } from './DataClassification';
import { InsiderAlert } from './InsiderAlert';
import { LicensingFlag } from './LicensingFlag';
import { nvtypes } from '../msm/app-br-edm/strings';

export interface Ibr {
  key: string;
  name: string;
  description: string;
  type: string;
  userType: string;
  userTypes?: string[];
  certifiable: boolean;
  autoApprovalFlag: boolean;
  autoApprovalCondition: string;
  roleOwner: string;
  roleActivityFlag: string;
  roleDependency: string;
  functionalArea: string;
  primaryWGApprover: string;
  secondaryWGApprover: string;
  maxAllowedDays: number;
  defaultAllowedDays: number;
  startDttm: string;
  endDttm: string;
  approvalLevel: number;
  restrictedRole: boolean;
  dataPrivacy: boolean;
  insiderAlert: string;
  licensing: string;
  dataClassification: string;
  provisionProfilesAndPolicies: boolean;
  productionSupport: boolean;
  approvalType: string;
  privilegedAccess: string;
  igaAppIDs: number[];
  mappedApps?: SimpleApplication[];
  profile: string;
  active: boolean;
  visible: boolean;
  selfCertPeriod: number;
  certActionPeriod: number;
  humanDataApprovalFlag: boolean;
  humanDataApprovalFilter?: string;
  defaultApproverIds?: string;
  riskScoreMultiplier?: string;
  autoExtensionFlag: boolean;
  autoExtensionCondition?: string;
  maxAutoExtensionAllowed?: number | null | string;
  maxAutoApprovalAllowed?: number | null | string;
}

export class Br {
  key = '';
  name = '';
  description = '';
  type = nvtypes.find((x) => x.val === 'GIASecurity').val;
  userTypes: string[] = [];
  certifiable = true;
  autoApprovalFlag = false;
  autoApprovalCondition = '';
  roleOwner = constants.roleOwner;
  roleActivityFlag = constants.tbd;
  roleDependency = constants.tbd;
  functionalArea = constants.tbd;
  primaryWGApprover = '';
  secondaryWGApprover = '';
  maxAllowedDays = constants.maxAllowedDays;
  defaultAllowedDays = constants.defaultAllowedDays;
  startDttm = '';
  endDttm = '';
  approvalLevel = 1;
  restrictedRole = false;
  dataPrivacy: boolean;
  insiderAlert: InsiderAlert;
  licensing: LicensingFlag = LicensingFlag.NotSet;
  dataClassification: DataClassification;
  provisionProfilesAndPolicies = false;
  productionSupport = false;
  approvalType = constants.approvalType;
  privilegedAccess = '';
  profile = '';
  active = true;
  visible = true;
  selfCertPeriod: string;
  certActionPeriod: number;
  humanDataApprovalFlag = false;
  autoExtensionFlag = false;
  autoExtensionCondition: string;
  maxAutoExtensionAllowed: number | null | string = 720;
  maxAutoApprovalAllowed: number | null | string = 720;
  humanDataApprovalFilter = '';
  defaultApproverIds: string[] = [];
  riskScoreMultiplier = '1.00';
  mappedApps: SimpleApplication[] = [];

  constructor(params: Ibr, apps?: Application[]) {
    if (params) {
      this.key = params.key || '';
      this.name = params.name || '';
      this.description = params.description || '';
      this.type = params.type || '';
      this.certifiable = params.certifiable;
      this.autoApprovalCondition = params.autoApprovalCondition || '';
      this.autoApprovalFlag = params.autoApprovalFlag;
      this.roleOwner = params.roleOwner || constants.roleOwner;
      this.roleActivityFlag = params.roleActivityFlag || constants.tbd;
      this.roleDependency = params.roleDependency || constants.tbd;
      this.functionalArea = params.functionalArea || constants.tbd;
      this.primaryWGApprover = params.primaryWGApprover || '';
      this.secondaryWGApprover = params.secondaryWGApprover || '';
      this.maxAllowedDays = params.maxAllowedDays || constants.maxAllowedDays;
      this.defaultAllowedDays =
        params.defaultAllowedDays || constants.defaultAllowedDays;
      this.startDttm = params.startDttm || '';
      this.endDttm = params.endDttm || '';
      this.approvalLevel = params.approvalLevel || 1;
      this.restrictedRole = params.restrictedRole;
      this.dataPrivacy = params.dataPrivacy;
      const rskScrMlt = params.riskScoreMultiplier
        ? parseFloat(params.riskScoreMultiplier)
        : -1;
      if (rskScrMlt >= 0 && rskScrMlt <= 1) {
        this.riskScoreMultiplier = rskScrMlt.toFixed(2);
      } else {
        this.riskScoreMultiplier = '1.00';
      }

      this.insiderAlert = params.insiderAlert as InsiderAlert;
      this.licensing =
        (params.licensing as LicensingFlag) || LicensingFlag.NotSet;
      this.dataClassification = params.dataClassification as DataClassification;
      this.provisionProfilesAndPolicies = params.provisionProfilesAndPolicies;
      this.productionSupport = params.productionSupport;
      this.approvalType = params.approvalType || constants.approvalType;
      this.privilegedAccess = params.privilegedAccess || '';
      this.profile = params.profile || '';
      this.active = params.active;
      this.visible = params.visible;
      this.selfCertPeriod = numToStr(params.selfCertPeriod);
      this.certActionPeriod = params.certActionPeriod;
      this.humanDataApprovalFlag = params.humanDataApprovalFlag;
      this.autoExtensionFlag = params.autoExtensionFlag;
      this.autoExtensionCondition = params.autoExtensionCondition;
      this.maxAutoExtensionAllowed = params.maxAutoExtensionAllowed;
      this.maxAutoApprovalAllowed = params.maxAutoApprovalAllowed;
      if (this.autoExtensionCondition === null) {
        this.autoExtensionCondition = '';
      }
      if (this.maxAutoExtensionAllowed === null) {
        this.maxAutoExtensionAllowed = '';
      }
      if (this.maxAutoApprovalAllowed === null) {
        this.maxAutoApprovalAllowed = '';
      }
      this.humanDataApprovalFilter = params.humanDataApprovalFilter || '';
      const userType = params.userType;
      const igaAppIDs = params.igaAppIDs || [];
      if (typeof userType === 'string' && userType.length > 0) {
        this.userTypes = userType.split(',');
      } else if (Array.isArray(params.userTypes)) {
        this.userTypes = params.userTypes;
      }
      let daIds = [];
      if (typeof params.defaultApproverIds === 'string') {
        daIds = params.defaultApproverIds.trim().split(',');
      } else if (Array.isArray(params.defaultApproverIds)) {
        daIds = params.defaultApproverIds;
      }
      this.defaultApproverIds = daIds.filter((d) => d.trim());
      if (!params.mappedApps && apps) {
        const mapped = apps.map((app) => new SimpleApplication(app));
        igaAppIDs.forEach((igaAppId) => {
          const found = mapped.find((app) => app.igaAppID === igaAppId);
          if (found) found.selected = true;
        });
        this.mappedApps = mapped;
      } else if (Array.isArray(params.mappedApps)) {
        this.mappedApps = params.mappedApps;
      }
    }
  }

  get userType(): string {
    return this.userTypes.join();
  }

  get defaultApproverIdsStr(): string {
    return (
      this.defaultApproverIds?.filter((id: string) => id?.trim())?.join(',') ||
      ''
    );
  }

  get igaAppIDs(): number[] {
    const apps = [];
    this.mappedApps.forEach((app) => {
      if (app.selected) {
        apps.push(app.igaAppID);
      }
    });
    return apps;
  }

  getPayload(): Ibr {
    const obj: Ibr = {
      key: this.key,
      name: this.name,
      description: this.description || '',
      type: this.type,
      userType: this.userType,
      certifiable: this.certifiable,
      autoApprovalFlag: this.autoApprovalFlag,
      autoApprovalCondition: this.autoApprovalCondition || '',
      roleOwner: this.roleOwner,
      roleActivityFlag: this.roleActivityFlag,
      roleDependency: this.roleDependency,
      functionalArea: this.functionalArea,
      primaryWGApprover: this.primaryWGApprover,
      secondaryWGApprover: this.secondaryWGApprover || '',
      approvalLevel: this.approvalLevel,
      startDttm: this.startDttm !== '' ? formatDate(this.startDttm) : '',
      endDttm: this.endDttm !== '' ? formatDate(this.endDttm) : '',
      maxAllowedDays: this.maxAllowedDays,
      defaultAllowedDays: this.defaultAllowedDays,
      restrictedRole: (() => {
        if (this.restrictedRole === undefined) {
          return false;
        } else {
          return this.restrictedRole;
        }
      })(),
      dataPrivacy: this.dataPrivacy,
      insiderAlert: this.insiderAlert,
      licensing: this.licensing,
      dataClassification: this.dataClassification || '',
      provisionProfilesAndPolicies: this.provisionProfilesAndPolicies,
      productionSupport: this.productionSupport,
      approvalType: this.approvalType,
      privilegedAccess: this.privilegedAccess,
      igaAppIDs: this.igaAppIDs,
      profile: this.profile || '',
      active: this.active,
      visible: this.visible,
      selfCertPeriod: parseInt(this.selfCertPeriod, 10),
      certActionPeriod: this.certActionPeriod,
      humanDataApprovalFlag: this.humanDataApprovalFlag || false,
      humanDataApprovalFilter: this.humanDataApprovalFilter || '',
      defaultApproverIds: this.defaultApproverIdsStr || '',
      riskScoreMultiplier: this.riskScoreMultiplier,
      autoExtensionFlag: this.autoExtensionFlag || false,
      autoExtensionCondition: this.autoExtensionCondition,
      maxAutoExtensionAllowed: (() => {
        if (this.maxAutoExtensionAllowed === null) {
          return null;
        } else if (typeof this.maxAutoExtensionAllowed === 'string') {
          return parseInt(this.maxAutoExtensionAllowed, 10) || null;
        } else {
          // It's already a number
          return this.maxAutoExtensionAllowed;
        }
      })(),
      maxAutoApprovalAllowed: (() => {
        if (this.maxAutoApprovalAllowed === null) {
          return null;
        } else if (typeof this.maxAutoApprovalAllowed === 'string') {
          return parseInt(this.maxAutoApprovalAllowed, 10) || null;
        } else {
          // It's already a number
          return this.maxAutoApprovalAllowed;
        }
      })(),
    };

    if (!obj.autoApprovalCondition) delete obj.autoApprovalCondition;
    if (!obj.endDttm) delete obj.endDttm;
    if (!obj.startDttm) delete obj.startDttm;
    if (!obj.profile) delete obj.profile;
    if (!obj.secondaryWGApprover) delete obj.secondaryWGApprover;
    if (isNaN(obj.selfCertPeriod)) delete obj.selfCertPeriod;
    if (!this.autoExtensionCondition) {
      delete obj.autoExtensionCondition;
    }
    if (typeof this.maxAutoExtensionAllowed !== 'number') {
      delete obj.maxAutoExtensionAllowed;
    } else if (this.maxAutoExtensionAllowed >= 0) {
      obj.maxAutoExtensionAllowed = this.maxAutoExtensionAllowed;
    }

    if (typeof this.maxAutoApprovalAllowed !== 'number') {
      delete obj.maxAutoApprovalAllowed;
    } else if (this.maxAutoApprovalAllowed >= 0) {
      obj.maxAutoApprovalAllowed = this.maxAutoApprovalAllowed;
    }
    return obj;
  }

  getMsMPayload(): IbrValPayload {
    const obj: IbrValPayload = {
      ProvisionProfilesAndPolicies: this.provisionProfilesAndPolicies,

      IsActive: this.active,
      IsVisible: this.visible,
      RestrictedRoleDisplayFlag: (() => {
        if (this.restrictedRole === undefined) {
          return false;
        } else {
          return this.restrictedRole;
        }
      })(),
      IsCertifiable: this.certifiable,
      AutoApprovalFlag: this.autoApprovalFlag,
      AutoApprovalCondition: this.autoApprovalCondition,

      BusinessRoleKey: this.key,
      BusinessRoleName: this.name,
      BusinessRoleDescription: this.description || '',
      BusinessRoleType: this.type,
      BusinessRoleUserType: this.userType,
      PrivilegedAccessFlag: this.privilegedAccess,
      IGAAppIds: this.igaAppIDs,

      RoleOwner: this.roleOwner,
      RoleActivityFlag: this.roleActivityFlag,
      RoleDependency: this.roleDependency,
      FunctionalArea: this.functionalArea,

      ApprovalLevel: this.approvalLevel,
      ApprovalType: this.approvalType,
      LicensingFlag: this.licensing,
      PrimaryApproverWorkgroup: this.primaryWGApprover,
      MaxAllowedDays: this.maxAllowedDays,
      DefaultAllowedDays: this.defaultAllowedDays,
      HumanDataApprovalFlag: this.humanDataApprovalFlag || false,
      HumanDataApprovalFilter: this.humanDataApprovalFilter || '',
      DefaultApproverIds: this.defaultApproverIdsStr,
      RiskScoreMultiplier: this.riskScoreMultiplier,
      InsiderAlertFlag: this.insiderAlert,
      BusinessRoleDataClassification: this.dataClassification,
      DataPrivacyFlag: this.dataPrivacy,
      CertActionPeriod: this.certActionPeriod,
      AutoExtensionFlag: this.autoExtensionFlag || false,
      AutoExtensionCondition: this.autoExtensionCondition,
      MaxAutoExtensionAllowed: (() => {
        if (this.maxAutoExtensionAllowed === null) {
          return null;
        } else if (typeof this.maxAutoExtensionAllowed === 'string') {
          return parseInt(this.maxAutoExtensionAllowed, 10) || null;
        } else {
          return this.maxAutoExtensionAllowed;
        }
      })(),
      MaxAutoApprovalAllowed: (() => {
        if (this.maxAutoApprovalAllowed === null) {
          return null;
        } else if (typeof this.maxAutoApprovalAllowed === 'string') {
          return parseInt(this.maxAutoApprovalAllowed, 10) || null;
        } else {
          return this.maxAutoApprovalAllowed;
        }
      })(),
      BusinessRoleStartDttm:
        this.startDttm !== '' ? formatDate(this.startDttm) : '',
      BusinessRoleEndDttm: this.endDttm !== '' ? formatDate(this.endDttm) : '',
    };
    if (
      this.insiderAlert !== InsiderAlert.PotInsider &&
      !this.riskScoreMultiplier
    ) {
      delete obj.RiskScoreMultiplier;
    }

    if (this.secondaryWGApprover)
      obj.SecondLevelApproverWorkgroup = this.secondaryWGApprover;
    if (this.profile) obj.Profile = this.profile;

    const selfCertPeriod = parseInt(this.selfCertPeriod, 10);
    if (!isNaN(selfCertPeriod)) obj.SelfCertPeriod = selfCertPeriod;

    if (!this.autoExtensionCondition) {
      delete obj.AutoExtensionCondition;
    }
    if (typeof this.maxAutoExtensionAllowed !== 'number') {
      delete obj.MaxAutoExtensionAllowed;
    } else if (this.maxAutoExtensionAllowed >= 0) {
      obj.MaxAutoExtensionAllowed = this.maxAutoExtensionAllowed;
    }
    if (typeof this.maxAutoApprovalAllowed !== 'number') {
      delete obj.MaxAutoApprovalAllowed;
    } else if (this.maxAutoApprovalAllowed >= 0) {
      obj.MaxAutoApprovalAllowed = this.maxAutoApprovalAllowed;
    }
    if (!obj.AutoApprovalCondition) delete obj.AutoApprovalCondition;

    return obj;
  }
}

function numToStr(num: number): string {
  if (!num || isNaN(num)) return '';
  return `${parseInt(`${num}`)}` || '';
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const timezoneOffsetMinutes = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - timezoneOffsetMinutes);
  date.setUTCHours(0, 0, 0, 0);
  const isoString = date.toISOString().replace(/\.000Z$/, '');

  return isoString;
}
