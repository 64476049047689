import { AuthService } from '../auth/auth.service';
interface INavItem {
  name: string;
  route: string;
  children?: INavItem[];
}

export class NavItem {
  name: string;
  route?: string;
  children?: NavItem[];
  isExpanded = false;

  constructor(obj: INavItem, base?: string) {
    this.name = obj.name;
    this.route = `${base || ''}${obj.route}`;
    if (obj.children) {
      this.children = obj.children.map(
        (child) => new NavItem(child, this.route),
      );
    }
  }
}

export function getNavItems(authService: AuthService): NavItem[] {
  const navItems: NavItem[] = [
    new NavItem({ name: 'Home', route: '/home' }),
    new NavItem({ name: 'Security model', route: '/securitymodel' }),
  ];
  // Check if the current user is an admin and add "Releases" if true
  if (authService.currUser?.isAdmin) {
    navItems.push(new NavItem({ name: 'Releases', route: '/release' }));
  }

  return navItems;
}
