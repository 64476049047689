import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/models/Application';
import { AppWithMappedBrs, IMappedBr } from 'src/app/models/AppWithMapping';
import { IActionPkg } from '../models/Actions';
import { AppDeets } from '../models/AppDeets';
import { msmActions, msmObjectives } from './actions-objective';
import { MsmApiService } from './msm-api.service';
import { AppOnboardingStatus } from '../models/AppOnboardingStatus';

/**
 * Manage security model
 * @description determine which path/action should be managed
 * AccPkg, App Br Edm, or Ddtdsdsg
 */
@Injectable({
  providedIn: 'root',
})
export class MsmService {
  fg: FormGroup;
  appWithMapping = new AppWithMappedBrs();
  appDeets: AppDeets;
  appStatus = new AppOnboardingStatus();
  childActive = false;
  filteredBusinessRoles: any[] = [];

  private subs: Subscription[] = [];

  constructor(
    fb: FormBuilder,
    private apiService: MsmApiService,
    private router: Router,
  ) {
    this.fg = fb.group({
      objective: [{ value: '', disabled: false }, Validators.required],
      action: [{ value: '', disabled: true }, Validators.required],
      app: [{ value: '', disabled: true }, Validators.required],
      businessRole: [{ value: '', disabled: true }, Validators.required],
      accPkg: [{ value: '', disabled: true }, Validators.required],
    });
  }

  onInit(): void {
    this.subs.push(
      this.acObjective.valueChanges.subscribe(this.objectiveChange.bind(this)),
    );
    this.subs.push(
      this.acAction.valueChanges.subscribe(this.actionChange.bind(this)),
    );
    this.subs.push(
      this.acApp.valueChanges.subscribe(this.appChange.bind(this)),
    );
  }

  onDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  resetFG(): void {
    this.acObjective.reset('');
  }

  doneUpdating(): void {
    this.resetFG();
    this.childActive = false;
    this.router.navigate(['/securitymodel']);
    this.apiService.clearEntDimCache();
  }

  private objectiveChange(v: string): void {
    this.enableControl(this.acAction, !!v);
  }

  private actionChange(): void {
    this.enableControl(
      this.acApp,
      this.isMsmActionUpdateAppDeets ||
        this.isMsmActionElevateToL3Onboard ||
        this.isMsmActionElevateToL3Connect ||
        this.isMsmActionUpdateBr ||
        this.isMsmActionUpdateEdm,
    );
    this.enableControl(this.acAccPkg, this.isMsmActionAccPkgUpdate);
  }

  private appChange(app: Application): void {
    if (app && this.acObjective?.value === msmObjectives.mbr) {
      this.apiService.getAppWithMappedBrs(this.igaAppID).subscribe((data) => {
        this.appWithMapping = data;
        this.filteredBusinessRoles = this.appWithMapping.mappedBusinessRoles;
      });
      this.apiService
        .getOnboardingStatus(this.igaAppID)
        .subscribe((appStatus) => {
          this.appStatus = appStatus;
        });
      this.enableControl(this.acBr, true);
    } else if (app && this.acObjective?.value === msmObjectives.ma) {
      this.apiService
        .getOnboardingStatus(this.igaAppID)
        .subscribe((appStatus) => {
          this.appStatus = appStatus;
        });
      this.enableControl(this.acBr, false);
    } else {
      this.enableControl(this.acBr, false);
    }
  }

  private enableControl(control: AbstractControl, enableControl: boolean) {
    control.reset('');
    enableControl ? control.enable() : control.disable();
  }
  resetBannerFrReleasePage() {
    this.acAction.setValue({ name: null });
    this.acBr.setValue({ name: null });
    this.acApp.setValue({ name: null });
  }
  /* --------------------------------------- getters --------------------------------------- */
  /** AbstractControl value is string */
  get acObjective(): AbstractControl {
    return this.fg.get('objective');
  }
  /** AbstractControl value is `IActionPkg` */
  get acAction(): AbstractControl {
    return this.fg.get('action');
  }
  /** AbstractControl value is `Application` */
  get acApp(): AbstractControl {
    return this.fg.get('app');
  }
  /** AbstractControl value is `IMappedBr` */
  get acBr(): AbstractControl {
    return this.fg.get('businessRole');
  }
  /** AbstractControl value is `IMappedBr` */
  get acAccPkg(): AbstractControl {
    return this.fg.get('accPkg');
  }
  get acCombinedAOI(): AbstractControl {
    return this.fg.get('mappedAreaOfInterests');
  }
  // -------------------------- Application values
  /** @returns current `Application` */
  get currApp(): Application {
    return this.acApp.value;
  }
  /** @returns current Application airId `number` */
  get airId(): number {
    return this.currApp ? this.currApp.airID : null;
  }
  /** @returns current Application igaAppID `number` */
  get igaAppID(): number {
    return this.currApp ? this.currApp.igaAppID : null;
  }
  /** @returns current Application name `string` */
  get appDisplayName(): string {
    return this.currApp ? this.currApp.displayName : '';
  }
  /** @returns current Application description `string` */
  get appDesc(): string {
    return this.currApp ? this.currApp.description : '';
  }
  /** @returns current Application onboarding lvl `string` */
  get onboardingLvl(): string {
    return this.currApp ? this.currApp.onboardingLevel : '';
  }
  /** @returns current Application active `boolean` */
  get appActive(): boolean {
    return this.currApp ? this.currApp.active : true;
  }
  /** @returns current Application visible lvl `boolean` */
  get appVisible(): boolean {
    return this.currApp ? this.currApp.visible : true;
  }
  /** @returns current Application onboarding status `string` */
  get onboardingStatus(): string {
    return this.currApp ? this.appStatus.onboardingStatus : '';
  }
  // -------------------------- Mapped brs
  /** @returns current Application `IMappedBr[]` */
  get appMappedBrs(): IMappedBr[] {
    return this.appWithMapping.mappedBusinessRoles;
  }
  // -------------------------- MSM action
  /** @returns current msm action value `string` */
  get msmActionName(): string {
    return this.acAction.value?.name;
  }
  /** @returns current msm action link `string` */
  get msmActionLink(): string {
    return this.acAction.value?.link;
  }
  /** @returns current msm action link `string` */
  get msmActionSubUrl(): string {
    return this.acAction.value?.subUrl;
  }
  /** @returns `true` if the current action is Update application */
  get isMsmActionUpdateAppDeets(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateApp.link
    );
  }
  /** @returns `true` if the current action is Onboard application to myAccess */
  get isMsmActionElevateToL3Onboard(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.onboardApp.link
    );
  }
  /** @returns `true` if the current action is Connect Application to myAccess */
  get isMsmActionElevateToL3Connect(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.connectApp.link
    );
  }
  /** @returns `true` if the current action is Update business role */
  get isMsmActionUpdateBr(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateBr.link
    );
  }
  /** @returns `true` if the current action is Update business role to dimension mapping */
  get isMsmActionUpdateEdm(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateEdm.link
    );
  }
  /** @returns `true` if the current action is Update dimensions */
  get isMsmActionDim(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateDims.link
    );
  }
  /** @returns `true` if the current action is Update dimension types */
  get isMsmActionDimType(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateDimTypes.link
    );
  }
  /** @returns `true` if the current action is Update data selections */
  get isMsmActionDataSel(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateDataSelections.link
    );
  }
  /** @returns `true` if the current action is Update data selection groups */
  get isMsmActionDataSelGrp(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateDataSelectionGroups.link
    );
  }
  /** @returns `true` if the current action is Create access package */
  get isMsmActionAccPkgCreate(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.createAccPkg.link
    );
  }
  /** @returns `true` if the current action is Update access package */
  get isMsmActionAccPkgUpdate(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateAccPkg.link
    );
  }
  /** @returns `true` if the current action is Acc Pkg Generate dimension mapping */
  get isMsmActionAccPkgDimMapUpdt(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.genDimMapping.link
    );
  }
  /** @returns `true` if the currect action is Update role filters (Misc Config) */
  get isMsmActionMcRoleFilters(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateRoleFilters.link
    );
  }
  /** @returns `true` if the current action is Update Role filter selections (Misc Config) */
  get isMsmActionMcRoleFilterSelections(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateRoleFilterSelections.link
    );
  }
  /** @returns `true` if the current action is Update SOD (Manage-BusinessRole) */
  get isMsmActionSODs(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link === msmActions.updateSod.link
    );
  }
  /** @returns `true` if the currect action is Update areas of interest (Misc Config) */
  get isMsmActionAOIs(): boolean {
    return (
      (this.acAction.value as IActionPkg)?.link ===
      msmActions.updateAreaOfInterests.link
    );
  }
  // -------------------------- BR info
  /** @returns current Br key `string` */
  get brKey(): string {
    return this.acBr.value ? (this.acBr.value as IMappedBr).key : '';
  }
  /** @returns current Br name `string` */
  get brName(): string {
    return this.acBr.value ? (this.acBr.value as IMappedBr).name : '';
  }
}
